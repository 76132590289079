<template>
    <div id="app">
        <router-view v-if="!isLocked" class="view"></router-view>

    </div>
</template>

<script>

import {mapActions} from "vuex";
    import {LOCAL_STORAGE_PANEL_SECRET} from "@/store/modules/User";

    export default {
        name: 'app',
        components: {
        },

        data() {
            return {
                languages: [
                    {language: 'en', title: 'English'},
                    {language: 'es', title: 'Česky'}
                ],
                secretValue: "",
                isLocked: true
            }
        },

        methods: {
            ...mapActions('user', ['loginUsingSecret']),

            authenticate(){
                const urlSecret = this.$route.query.secret;
                const secret = urlSecret ? urlSecret : process.env.VUE_APP_AUTH_SECRET;

                if ( typeof secret != "undefined" && secret !== null) {
                    this.loginUsingSecret(secret).then( (response) => {
                        if ( response ) {
                            this.isLocked = !this.isLocked;
                        }
                    } ).catch(() => {
                        alert( this.$t('login.invalid_secret') );
                    })
                } else {
                    alert( this.$t('login.secret_missing') );
                }
            },
        },
        computed: {
            hasSecret() {
                return localStorage.getItem(LOCAL_STORAGE_PANEL_SECRET) != null;
            },
        },

        mounted() {
            this.authenticate();
        },
    }
</script>

<style>
    :root {
        --base-color: #78BE13;
    }

    html {
        /* Disable all scrollbars. */
        overflow: hidden !important;
    }

    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 0;
        display: inline-block;
        align-items: center;
        justify-content: center;
    }

    .pointer {
        cursor: pointer;
    }

    .base_layer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100vw;
        height: 100vh;
    }

    .base_layer.wizard {
        background-color: #6D717A;
        justify-content: space-between;
    }

    .base_layer.notification {
        background-color: var(--base-color);
        justify-content: center;
    }

    .want_ticket {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 330px;
        height: 100px;
        background: white;
        border-radius: 40px;
        color: black;
        font-size: x-large;
        border: none;
        box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
        font-weight: bolder;
        text-decoration: none !important;
    }

    .want_ticket:active {
        background-color: var(--base-color);
        color: white;
        text-decoration: none !important;
    }

    .want_ticket[disabled] {
        background: #939393;
        color: black;
    }

    .footer {
        width: 100%;
        min-height: 20vh;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: row;
        padding: 3vh 3vw;
    }

    .ps {
        width: 100%;
        display: block;
        overflow: auto;
    }

    .cards-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 0 3vw;
    }

    .card {
        margin-top: 1vw;
        margin-bottom: 1vw;
        margin-right: 2vw;
    }

    .secret-form {
        width: 100vw;
    }
    .secret-form > form {
        width: 300px;
        margin: 50px auto 0;
    }
</style>
