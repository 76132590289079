<template>
  <div class="base_layer wizard" @click="hideKeyBoard()">

    <Loading :active.sync="isLoadingData"
             :is-full-page="false"
             :height="125"
             :width="125"
             backgroundColor="#6D717A"
             color="#7ABE12"
    ></Loading>

    <Header :text="$t('student_identification.identification')" v-on:click.stop.prevent="" @back="goBack()"/>

    <div ref="studyRecapScroll" v-on:click.stop.prevent="">
      <div class="in_middle">
        <input type="text" @focus="showKeyBoard" data-layout="compact" class="want_ticket_wide" :placeholder="$t('student_identification.loginName')" v-model="xlogin" >
      </div>

      <div class="in_middle2">
        <div class="add_next">
          <span class="info">{{ $t('student_identification.info') }}</span>
        </div>
      </div>
      <div class="generate_ticket_button" v-if="!visible">
        <button id="generate_ticket" :disabled="this.isGenerateTicketDisabled" @click="showLoad()">
          {{ $t('student_identification.generate') }}
        </button>
      </div>
    </div>
    <div class="myKeyboard " v-on:click.stop.prevent="">
      <vue-touch-keyboard
          id="keyboard"
          :options="options"
          v-if="visible"
          :layout="layout"
          :accept="hideKeyBoard"
          :next="hideKeyBoard"
          :input="input"
      />
    </div>
    <div class="row_bottom" v-on:click.stop.prevent="">
      <div class="generate_ticket_button_right">
        <go-cancel-arrow></go-cancel-arrow>
      </div>
    </div>

  </div>
</template>

<script>
import Header from "@/components/elements/Header.vue"
import GoCancelArrow from "@/components/elements/GoCancelArrow";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import {mapGetters, mapActions} from "vuex";

import {restoreTimeMilis} from '@/store/config';
import moment from "moment";

import VueTouchKeyboard from "vue-touch-keyboard";
require("vue-touch-keyboard/dist/vue-touch-keyboard.css");

export default {
  name: "StudentIdentification",
  components: {
    "vue-touch-keyboard": VueTouchKeyboard.component,
    Header,
    GoCancelArrow,
    Loading,
  },
  data() {
    return {
      xlogin : "",
      isLoadingData: false,
      didFinishLoadingData: false,

      timeout: null,

      visible: false,
      layout: "normal",
      input: null,
      options: {
        useKbEvents: false,
        preventClickEvent: false
      },

    }
  },

  created() {
    this.timeout = setTimeout(() => this.$root.$emit('Start'), restoreTimeMilis.restoreTime);
  },

  destroyed() {
    clearTimeout(this.timeout);
  },

  mounted() {
   this.$nextTick(() => {
      this.$refs.studyRecapScroll.scrollTo(0, this.getRecapYAxis);
    })
  },

  computed: {
    isGenerateTicketDisabled() {
      return this.xlogin.trim() ==="";
    },

    ...mapGetters('scrollTo', ['getRecapYAxis'])

  },
  methods: {

    showKeyBoard(e) {
      this.input = e.target;
      this.layout = e.target.dataset.layout;
      if (!this.visible)
        this.visible = true
    },
    hideKeyBoard() {this.visible = false;},

    goBack() {
      this.setStudyRecapYAxis(this.$refs.studyRecapScroll.scrollTop)
      this.$root.$emit('toStudyRequirementRecap');
    },

    ...mapActions('queueItemTo', [ 'setStartTime', 'setEndTime','setXname']),
    ...mapActions('scrollTo', ['setStudyRecapYAxis']),

    async send() {
      try {
        this.setStartTime(moment().format("HH:mm").toString());
        this.setXname(this.xlogin);

        await this.sendQueueTo();

        await this.getImage();

        this.goToTicket()
      } catch (e) {
        if (e === 409) {
          this.isLoadingData = false;
        }
        this.$root.$emit('toError');
      }
    },
    ...mapActions('queueItemTo', ['sendQueueTo', 'getImage']),

    async showLoad() {
      this.isLoadingData = true;
      await this.send();
    },

    goToTicket() {
      this.isLoadingData = false;
      this.$root.$emit('toTicket');
    },

  },


}

</script>

<style scoped>
.in_middle {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.in_middle2 {
  margin-top: 25px;
}

.want_ticket_wide {
  text-align: left;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 700px;
  height: 80px;
  background-color: white;
  border-radius: 40px;
  color: rgb(0, 0, 0);
  font-size: x-large;
  border: none;
  font-weight: bolder;
}


.info {
  width: 571px;
  margin-left: 15px;
  color: white;
  font-size: x-large;
}

#generate_ticket {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 470px;
  height: 100px;
  background: #7ABE12;
  border-radius: 40px;
  color: white;
  font-size: x-large;
  border: none;
  -webkit-box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
  -moz-box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
  box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
  font-weight: bolder;
  float: right;
}

#generate_ticket:active {
  background-color: white;
  color: black;
  transform: translateY(1px);
  filter: saturate(150%);
}

#generate_ticket:disabled {
  background-color: #a9a9ad;
  color: #727272;
}

.generate_ticket_button {
  float: left;
  position: absolute;
  left: 50%;
  bottom: 32%;
  transform: translateX(-50%);
}

.generate_ticket_button_right {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0.5em;
  display: flex;
  justify-content: center;
}

.row_bottom {
  width: 100%;
  height: 190px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: flex-end;
  padding: 0.5em;
  position: relative;
}

.add_next {
  margin: 0 auto;
  width: 620px;
  display: flex;
  align-items: center;
  justify-content: center;
}

::placeholder{
  color: black;
}
.in_middle input{
  padding-left: 25px;
  color: black
}
.myKeyboard{
  margin-top: 25px;
}

#keyboard {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 30px;

  z-index: 1000;
  width: 100%;
  height: 300px;
  font-size: 20px;
  max-width: 1000px;
  margin: 0 auto;
  padding: 1em;

  background-color: #EEE;
  border-radius: 10px;
}
</style>