<template>
  <div class="base_layer notification">
    <v-col class="touch_panel_container">
      <missing-data-overlay v-if="!isEverythingFilled"/>

      <v-row class="header_row">
        <div class="row_2">
          <div>
            <button class="language_button"
                    :class="this.$i18n.locale === 'en' ? 'cz_flag' : 'en_flag'"
                    v-on:click="changeLocale()"></button>
            <div class="info" style="width: auto">
              {{ this.$i18n.locale === 'en' ? 'Změnit jazyk' : 'Change language' }}
            </div>
          </div>
        </div>
        <div id="row_2">
          <h1 id="top_row_header">{{ $t('start.waiting_for_study_dep') }}</h1>
        </div>
        <div class="row_3">
          <digital-clock class="clock"/>
        </div>
      </v-row>

      <div class="middle_row">
        <button id="want_ticket" @click="toIdentity()"> {{ $t('start.ticket') }}</button>
        <p class="info">{{ $t('start.if_you_didnt_get') }}</p>
      </div>
      <div class="mm_logo" :class="this.$i18n.locale === 'en' ? 'en_mm_logo' : 'cz_mm_logo'">
      </div>
    </v-col>
  </div>
</template>

<script>
import DigitalClock from "@/components/elements/Clock.vue"
import {mapGetters} from "vuex";
import MissingDataOverlay from '../MissingDataOverlay.vue';

export default {
  name: "start",
  components: {
    DigitalClock,
    MissingDataOverlay,
  },

  mounted() {
    this.$i18n.locale = 'cz';

    document.title = 'Touch Panel MyMendelu';
  },

  computed: {
    ...mapGetters(['areStudyOpenHoursFilled', 'areStudyProblemsItemsFilled', 'areStudyProblemsCategoryFilled', 'areStudyFieldsFilled', 'areAssistantsFilled',]),

    isEverythingFilled() {
      return this.areStudyOpenHoursFilled
          && this.areStudyProblemsItemsFilled
          && this.areStudyProblemsCategoryFilled
          && this.areAssistantsFilled
          && this.areStudyFieldsFilled;
    },
  },

  methods: {
    changeLocale() {
      if (this.$i18n.locale === 'en') {
        this.$i18n.locale = 'cz';
      } else {
        this.$i18n.locale = 'en';
      }
    },

    toIdentity: function () {
      this.$root.$emit('toIdentity');
    },
  }

}
</script>

<style scoped>
.base_layer {
  background-color: white;
  background-image: url("../../assets/images/placeholder-left.png"), url("../../assets/images/placeholder-right.png");
  background-position: left bottom, 100% 70%;
  background-size: auto 40vw, auto 40vw;
  background-repeat: no-repeat;
}

.header_row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.row_3 {
  width: 14vw;
  padding: 5vh 3vw;
}

#top_row_header {
  padding-left: 15%;
  min-width: 55%;
  padding-right: 15%;
  font-size: 3vw;
  color: black;
  margin-top: 3%;
}

.cz_flag {
  background-image: url("../../assets/images/CZflag_square.png");
  background-size: 130px 130px;
  background-repeat: no-repeat;
}

.en_flag {
  background-image: url("../../assets/images/UKflag_square.png");
  background-size: 130px 130px;
  background-repeat: no-repeat;
}

.cz_mm_logo {
  background: url("../../assets/images/cz_mm_logo.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.en_mm_logo {
  background: url("../../assets/images/en_mm_logo.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.mm_logo {
  position: absolute;
  bottom: 3%;
  right: 1%;
  width: 15%;
  height: 5%
}

.touch_panel_container {
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%
}

.language_button {
  width: 128px;
  height: 128px;
  border: none;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  padding: 0;
  display: block;
  margin: 3vh 3vw;
  margin-bottom: 1vw !important;
  -webkit-box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
  -moz-box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
  box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
}

.language_button:active {
  background-color: white;
}

.middle_row {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 38vh;
}

#want_ticket {
  display: block;
  position: relative;
  width: 470px;
  height: 100px;
  background: #7ABE12;
  border-radius: 40px;
  color: white;
  font-size: xx-large;
  border: none;
  -webkit-box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
  -moz-box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
  box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
  margin-bottom: 20px;
}

#want_ticket:active {
  background-color: white;
  color: black;
}

.info {
  margin-top: 0px;
  width: 470px;
  font-size: large;
  font-weight: bold;
  color: grey;
  text-align: center;
  background: rgba(255, 255, 255, 0.767);
}
</style>
