<template>
    <div class="wrapper">
        <component :is="component"></component>
    </div>
</template>

<script>
import Start from "./pages/Start";
import Identity from "./pages/Identity";
import StudyField from "./pages/StudyField";
import StudyCategory from "./pages/StudyCategory";
import StudyRequirement from "./pages/StudyRequirement";
import StudyRequirementRecap from "./pages/StudyRequirementRecap";
import Ticket from "./pages/Ticket";
import End from "./pages/End";
import Closed from "./pages/Closed";
import ClosedPause from "./pages/ClosedPause";
import ClosedDueTo from "./pages/ClosedDueTo";
import StudyRequirementRecapMeetings from "./pages/StudyRequirementRecapMeetings";
import Error from "./pages/Error";
import NoAssistant from "./pages/NoAssistant";

import {mapActions, mapGetters} from 'vuex';

import SockJS from "sockjs-client";
import Stomp from "webstomp-client";

import {fullURL, restoreTimeMilis} from '@/store/config';
import moment from "moment";
import StudentIdentification from "@/components/pages/StudentIdentification";

export default {
    name: "CheckInMaster",

    components: {
        Start,
        Identity,
        StudyField,
        StudyCategory,
        StudyRequirement,
        StudyRequirementRecap,
        Ticket,
        End,
        Closed,
        ClosedPause,
        ClosedDueTo,
        StudyRequirementRecapMeetings,
        Error,
        NoAssistant,
        StudentIdentification
    },

    data() {
        return {
            component: null,

            connected: false,

            stompClient: null,
            socket: null,

            isLoading: null,

            toCloseOrPauseInterval: null,

            toOpenInterval: null
        }
    },

    created() {
        this.connect();
        this.setCurrentComponentFromStorage();
    },

    mounted() {
        this.$root.$on('Start', () => {
            this.component = 'Start';
            this.restoreState();
        });

        this.$root.$on('toIdentity', () => {
            this.component = 'Identity';
        });

        this.$root.$on('toStudyField', () => {
            this.component = 'StudyField';
        });

        this.$root.$on('toStudyCategory', () => {
            this.component = 'StudyCategory';
        });

        this.$root.$on('toStudyRequirement', () => {
            this.component = 'StudyRequirement';
        });

        this.$root.$on('toStudyRequirementRecap', () => {
            this.component = 'StudyRequirementRecap';
        });

        this.$root.$on('toTicket', () => {
            this.component = 'Ticket';
        });

        this.$root.$on('toEnd', () => {
            this.component = 'End';
        });

        this.$root.$on('toClosed', () => {
            this.component = 'Closed';
        });

        this.$root.$on('toPaused', () => {
            this.component = 'ClosedPause';
        });

        this.$root.$on('toClosedDueTo', () => {
            this.component = 'ClosedDueTo';
        });

        this.$root.$on('toMeeting', () => {
            this.component = 'StudyRequirementRecapMeetings';
        });

        this.$root.$on('toError', () => {
            this.component = 'Error';
        });

        this.$root.$on('toNoAssistant', () => {
            this.component = 'NoAssistant';
        });

        this.$root.$on('toIdentification', () => {
            this.component = 'StudentIdentification';
        });
    },

    destroyed() {
        this.disconnect();
    },

    computed: {
        ...mapGetters(['getCallInCurrentComponent']),
        ...mapGetters(['getClosedHoursReason', 'getStudyRestrictions', 'getStudyOpenHour', 'areStudyOpenHoursFilled', 'areStudyProblemsItemsFilled', 'areStudyProblemsCategoryFilled', 'areStudyFieldsFilled', 'areAssistantsFilled']),

        getCurrentDayStudyHours() {
            return this.getStudyOpenHour(moment().day().toString());
        },

    },

    methods: {
        ...mapActions(['fetchStudyRestrictions', 'setClosedHoursReasons', 'setStudyRestrictions', 'setStudyOpenHours', 'setAssistants', 'setStudyRequirement', 'setStudyCategory', 'setStudyFields', 'setCallInCurrentComponentStorage']),
        ...mapActions('queueItemTo', ['restoreState']), //good

        setCurrentComponentFromStorage() {
            this.component = this.getCallInCurrentComponent;
        },

        connect() {
            this.socket = new SockJS(fullURL.url + "socket");
            this.stompClient = Stomp.over(this.socket);

            // if (config.env === "production")
            this.stompClient.debug = () => {}; // Stop showing the socket logs

            this.stompClient.connect({},
                // eslint-disable-next-line no-unused-vars
                frame => {
                    this.connected = true;
                    this.stompClient.subscribe(`/study-department/touch-panel`, message => {
                        this.isLoading = true;

                        let item = JSON.parse(message.body);

                        this.setStudyFields(item.studyFields);
                        this.setStudyCategory(item.studyProblemsCategories);
                        this.setStudyRequirement(item.studyProblemsItems);
                        this.setAssistants(item.assistants);
                        this.setStudyOpenHours(item.studyOpenHours);
                        // this.setStudyRestrictions(item.studyClosedHours);
                        // this.setClosedHoursReasons(item.closedHoursReasons);

                        this.isLoading = false;
                    });

                    this.send();
                },
                error => {
                    // eslint-disable-next-line no-console
                    console.error('Socket connection error:', error);
                    // eslint-disable-next-line no-console
                    console.info('Reconnecting websocket in 10 seconds.');

                    this.connected = false;

                    setTimeout(() => this.connect(), 10000);
                },
            );
        },

        send() {
            if (this.stompClient && this.stompClient.connected) {
                this.stompClient.send("/app/study-department/request-touch-panel", null, {});
            }
        },

        disconnect() {
            if (this.stompClient) {
                this.stompClient.disconnect();
            }
            this.connected = false;
        },

        isClosed() {
            let currentDayStudyHours = this.getCurrentDayStudyHours;

            if (currentDayStudyHours !== undefined && currentDayStudyHours !== null) {

                let now = moment(moment(), "HH:mm");

                return (currentDayStudyHours.openTime === undefined && currentDayStudyHours.closeTime === undefined)
                    || (currentDayStudyHours.openTime === null && currentDayStudyHours.closeTime === null)
                    || (now.isSameOrBefore(moment(currentDayStudyHours.openTime, "HH:mm").subtract(restoreTimeMilis.startEndRerouteTime, "minute"))
                        || now.isSameOrAfter(moment(currentDayStudyHours.closeTime, "HH:mm").subtract(restoreTimeMilis.startEndRerouteTime, "minute")));

            }
            return true;
        },

        isPaused() {
            let currentDayStudyHours = this.getCurrentDayStudyHours;

            if (currentDayStudyHours !== undefined && currentDayStudyHours !== null && !this.isClosed()) {

                let now = moment(moment(), "HH:mm");

                return (currentDayStudyHours.pauseStart !== undefined && currentDayStudyHours.pauseEnd !== undefined)
                    && (currentDayStudyHours.pauseStart !== null && currentDayStudyHours.pauseEnd !== null)
                    && (now.isAfter(moment(currentDayStudyHours.pauseStart, "HH:mm").subtract(restoreTimeMilis.pauseRerouteTime, "minute"))
                    && now.isBefore(moment(currentDayStudyHours.pauseEnd, "HH:mm")));
            }
            return false;
        },


        /**
         * Checks every 10 seconds whether to open, pause or close the study department.
         */
        changePanelState() {

            if (this.isClosed()) {
                this.$root.$emit('toClosed');
            } else if (this.isPaused()) {
                this.$root.$emit('toPaused')
            } else {
                this.$root.$emit('Start');
                clearInterval(this.toOpenInterval)
            }
        },


        /**
         * Checks every 10 seconds if the study department is closed. If yes, emit toClosed event to show Closed.vue component.
         */
        closePanel() {
            if (this.isClosed()) {
                this.$root.$emit('toClosed');
                clearInterval(this.toCloseOrPauseInterval)
            }
        },

        /**
         * Checks every 10 seconds if there is a pause in the study department. If yes, emit toPaused event to show ClosedPause.vue component.
         */
        pausePanel() {
            if (this.isPaused()) {
                this.$root.$emit('toPaused');
                clearInterval(this.toCloseOrPauseInterval)
            }
        },


        async goToRestriction() {
            await this.fetchStudyRestrictions();
            let preModel = this.getStudyRestrictions.find(function (model) {
                return moment().isAfter(moment(model.timeFrom)) && moment().isBefore(moment(model.timeTo)) && model.closedHoursReason.type === 'department';
            });

            if (preModel !== undefined) {
                if (moment().isAfter(moment(preModel.timeFrom)) && moment().isBefore(moment(preModel.timeTo))) {
                    this.$root.$emit('toClosedDueTo');
                    clearInterval(this.toCloseOrPauseInterval);
                }
            }
        },

    },

    watch: {
        component: async function () {
            if (this.component === 'End') {
                await this.send();
                this.closePanel();
                this.pausePanel();
                this.goToRestriction();
            }

            if (this.component === 'Start') {
                this.toCloseOrPauseInterval = setInterval(async () => {
                    await this.send();
                    this.closePanel();
                    this.pausePanel();
                    this.goToRestriction();
                }, 10000)
            }

            if (this.component === 'Closed') {
                this.toOpenInterval = setInterval(async () => {
                    await this.send();
                    this.changePanelState();
                }, 10000)
            }

            if (this.component === 'ClosedPause') {
                this.toOpenInterval = setInterval(async () => {
                    await this.send();
                    this.changePanelState();
                }, 10000)
            }

            if (this.component !== 'Closed' && this.component !== 'Start' && this.component !== 'ClosedPause') {
                clearInterval(this.toOpenInterval)
                clearInterval(this.toCloseOrPauseInterval)
            }
        },
    }
}


</script>

<style scoped>

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

</style>
