import CheckInMaster from "../components/CheckInMaster";

import StudyRequirementRecapMeetings from "../components/pages/StudyRequirementRecapMeetings";

const routes = [
    { path: '/', name: 'CheckInMaster', component: CheckInMaster },
    { path: '*', redirect: {name: 'CheckInMaster'}},

    { path: '/a', name: 'StudyRequirementRecapMeetings', component: StudyRequirementRecapMeetings },
];

export default routes;
