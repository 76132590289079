<template>
    <div class="base_layer">

        <Header />

        <h1 id="top_row_header">{{ $t('ticket.your_ticket') }}</h1>

        <div class="in_middle">
            <div class="text_left">

                <div id="time_and_text">
                    <div class="maybe_time">
                        {{ $t('ticket.ticket_time') }}
                    </div>
                    <div class="maybe_time_time">
                        {{parseStartTime}}
                    </div>
                </div>

                <div id="numero_and_text">
                    <div id="queue_number">
                        <span>{{ getTicketNumber }}</span>
                    </div>
                    <div id="ticket_num">
                        <span>{{ $t('ticket.queue_number') }}</span>
                    </div>
                </div>
                <div id="text_how_much">
                    <span>{{ $t('ticket.takes_care_off_p1') }} {{$t('ticket.takes_care_off_p2')}}</span>
                    <!--                    <span>{{ $t('ticket.takes_care_off_p1') }} {{ getStudyRequirementLength }} {{$t('ticket.takes_care_off_p2')}}</span>-->
                </div>
                <div id="name">
                    <span> {{ showAssistant.name }} </span>
                </div>
                <div id="doors">
                    <span> {{ showAssistant.defaultRoom }} </span>
                </div>

            </div>
            <div class="image_div"> <img :src="this.getImage" class="image"> </div>
            <div class="qr_code">
                <qrcode style="float: right; margin-right: 1rem" :value="showQrData" :options="{ width: 170 }"></qrcode>
            </div>
        </div>

        <div class="row_bottom">
            <div class="generate_ticket_button">
                <button id="generate_ticket" @click="goToEnd()">{{ $t('ticket.close') }}</button>
            </div>
            <div class="generate_ticket_button_info">
                <span id="info_text">{{ $t('ticket.qr_code') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import Header from "@/components/elements/Header.vue"
    import {mapActions, mapGetters} from "vuex";

    import {restoreTimeMilis} from '../../store/config';

    export default {
        name: "Ticket",
        components: {
            Header,
        },

        data() {
            return {
                assistantName: null,
                assistantRoom: null,
                dataToShowInQr: null,

                timeout: null,

                image: null,
            }
        },

        created() {
            this.timeout = setTimeout(() => {
                this.$root.$emit('Start');
                this.restoreState();
            }, restoreTimeMilis.restoreTimeTicket);

            this.image = this.getImage;
        },

        destroyed() {
            clearTimeout(this.timeout);
        },

        computed: {
            ...mapGetters(['getAssistantId']),
            ...mapGetters('queueItemTo', ['getImage', 'getEndTime', 'getStartTime', 'getQueueItemUUID', 'getPersonId', 'getTicketNumber', 'getStudyRequirementLength']),


            showAssistant() {
                return this.getAssistantId(this.getPersonId);
            },

            parseStartTime() {
                return this.addNullIfShortTime(this.getStartTime.hour) + ":" + this.addNullIfShortTime(this.getStartTime.minute)
            },

            showQrData() {
                let queueItemId = this.getQueueItemUUID;

                let qrCodeToShowData = {
                    queueItemUUID: queueItemId,

                };
                return JSON.stringify(qrCodeToShowData);
            }
        },

        methods: {
            ...mapActions('queueItemTo', ['restoreState']), //good

            goToEnd() {
                this.$root.$emit('toEnd');
            },

            addNullIfShortTime(time) {
                if (time.toString().length === 1) {
                    return "0" + time.toString()
                } else {
                    return time.toString()
                }
            },
        }
    }
</script>


<style scoped>
    .base_layer {
        background-color: #6D717A;
    }

    .header {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        font-size: xx-large;
        color: white;
    }

    h1 {
        font-size: xxx-large;
        color: white;
    }

    .in_middle {
        margin-top: 0;
        padding-top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url("../../assets/images/bez_kukana.png");
        height: 340px;
        background-size: 900px 350px;
        background-repeat: no-repeat;
        background-position: center;
        width: 1110px;
    }

    #generate_ticket {
        justify-content: center;
        align-items: center;
        width: 320px;
        height: 100px;
        background: #7ABE12;
        border-radius: 40px;
        color: white;
        font-size: x-large;
        box-shadow: 0 0;
        font-weight: bolder;
        border: none;
        -webkit-box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
        -moz-box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
        box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
    }

    #generate_ticket:active {
        background-color: white;
        color: black;
    }

    .generate_ticket_button {
        width: 100%;
        display: inline-block;
    }

    .generate_ticket_button_info {
        width: 80%;
        display: inline-block;
        margin-top: 10px;
    }

    #info_text {
        font-size: large;
        font-weight: bolder;
        color: white;
    }

    .row_bottom {
        width: 100%;
        display: inline-block;
        flex-grow: 1;
    }

    .text_left {
        width: 40%;
        height: 80%;
        margin-right: 1rem;
        padding: 1.2rem 4rem;
    }

    .qr_code {
        width: 20%;
    }

    #queue_number {
        color: var(--base-color);
        font-weight: 900;
        font-size: 5em;
        margin-right: 10px;
        display: block;
        line-height: 1em;
    }

    #numero_and_text {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 0.4rem;
    }

    #time_and_text {
        display: flex;
        align-items: center;
        height: 2rem;
    }

    .maybe_time {
        font-size: 1.3rem;
        font-weight: bolder;
        width: 15rem;
        color: gray;
        text-align: left;
    }

    .maybe_time_time {
        font-size: 2rem;
        font-weight: bolder;
        width: 2.8rem;
        color: gray;
        text-align: left;
    }

    #ticket_num {
        color: gray;
        font-weight: bolder;
        font-size: 1.7em;
        line-height: 1em;
        margin-right: 0;
    }

    #name {
        color: gray;
        font-weight: bolder;
        font-size: x-large;
        margin-top: 5px;
        height: 29px;
        line-height: 1em;
        text-align: left;
    }

    #text_how_much {
        color: gray;
        font-size: larger;
        margin-top: 0.8rem;
        height: 23px;
        line-height: 1em;
        text-align: left;
    }

    #doors {
        height: 36px;
        color: gray;
        font-weight: 600;
        font-size: 2em;
        margin-top: 14px;
        line-height: 1em;
        text-align: left;
        margin-left: 47px;
    }

    .clock {
        color: white !important;
    }

    .image {
        width: 100%;
        height: 100%;
    }

    .image_div {
        width: 17%;
        height: 12rem;
        position: static;
    }

</style>
