<template>
    <div id="just_div" class="pointer">
            <div class="row">
                <a id="just_a" v-on:click="restore"> <img id="back_button" src="../../assets/images/cancel.png"> </a>
            </div>
            <div class="row">
                <span id="cancel" v-on:click="restore">{{ $t('go_cancel_arrow.cancel') }}</span>
            </div>

    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "GoCancelArrow",

        methods: {
            restore() {
                this.$root.$emit('Start');
                this.restoreState();
            },
            ...mapActions('queueItemTo', ['restoreState']),
        }
    }
</script>

<style scoped>
    #back_button {
        height: 64px;
        width: 64px;
    }

    #just_a {
        float: right;
    }

    #just_div {
        float: right;
        /*margin-top: 2rem;*/
        margin-right: 2rem;
        width: 10rem;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
    }

    #cancel {
        font-size: x-large;
        color: white;
        font-weight: bold;
        float: right;
        margin-top: 0;
        padding-top: 0;
    }

    .row {
        display: inline-block;
        margin-top: 0;
        padding-top: 0;
    }
</style>
