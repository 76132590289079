import {fullURL, backend_urls, loginPass, serviceAuthURL} from '../config';
import axios from 'axios';

export const LOCAL_STORAGE_TOKEN_KEY = "auth_token";
export const LOCAL_STORAGE_PANEL_SECRET = "login_secret";

const state = {
    token: null,
    tokenRefreshInterval: null,
};

const getters = {};

const actions = {
    login({commit}) {
        axios.post(  fullURL.url + backend_urls.login, {
            password: loginPass.password,
            username: loginPass.username,
        })
            .then((response) => {
                const token = response.data.token;
                localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
                commit('setToken', token);
            })
            .catch(() => {
                alert("Nastal problém s přihlášením. Zkontrolujte své uživatelské jméno a heslo.");
            });
    },

    loginUsingSecret({commit, dispatch}, secret) {
        if ( secret.secret ){
            secret = secret.secret.secret;
        }

        var formData = new URLSearchParams();
        formData.append('grant_type', "client_credentials");
        formData.append('client_id', process.env.VUE_APP_AUTH_CLIENT);
        formData.append('client_secret', secret);
        const options = {
            method: 'POST',
            url: serviceAuthURL.url,
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            data: formData
        }

        return axios.request(options)
            .then(function (response) {
                const token = response.data['access_token'];
                localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
                localStorage.setItem(LOCAL_STORAGE_PANEL_SECRET, secret);
                commit('setToken', token);
                if ( state.tokenRefreshInterval == null ){
                    dispatch('startRefreshingToken', {secret});
                }
                return response;
            });
    },

    logout({ commit }) {
        commit('resetAll');
        localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
    },

    startRefreshingToken({dispatch}, secret) {
        state['tokenRefreshInterval'] = setInterval(() => {
            dispatch('loginUsingSecret', { secret });
        }, 60000);
    },
};

const mutations = {
    setToken(state, token) {
        state.token = token;
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    },

    resetAll(state) {
        state.token = null;
        axios.defaults.headers.common['Authorization'] = null;
        clearInterval(state.tokenRefreshInterval);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
