<template>
    <div class="base_layer notification">
        <div id="basic">
            <div id="bye">
                <span>{{ $t('no_assistant.we_are_sorry') }}</span>
            </div>
            <div id="hope">
                <span>{{ $t('no_assistant.unfortunately') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import {restoreTimeMilis} from "../../store/config";

    export default {
        name: "NoAssistant",

        data() {
            return {
                langInterval: null,
                timeout: null,
            }
        },

        created() {
            this.restoreState();
        },

        mounted() {
            this.langInterval = setInterval(() => {
                this.changeLocale()
            }, restoreTimeMilis.changeLocaleTime)

            this.timeout = setTimeout(() => this.$root.$emit('Start'), restoreTimeMilis.restoreTimeError);
        },

        destroyed() {
            clearInterval(this.langInterval);
            clearTimeout(this.timeout);
        },

        computed: {
            ...mapGetters(['getStudyOpenHours', 'getStudyOpenHour'])
        },

        methods: {
            ...mapActions('queueItemTo', ['restoreState']), //good

            changeLocale() {
                if (this.$i18n.locale === 'en') {
                    this.$i18n.locale = 'cz';
                } else {
                    this.$i18n.locale = 'en';
                }
            },
        }
    }
</script>

<style scoped>
    #bye {
        color: white;
        font-size: 3em;
        font-weight: bolder;
    }

    #hope {
        color: white;
        font-weight: bolder;
        font-size: 2em;
        margin-top: 20px;
    }

</style>
