<template>
    <div class="base_layer notification" @click="close()">
        <div id="basic">
            <div id="bye">
                <span>{{ $t('end.goodbye') }}</span>
            </div>
            <div id="hope">
                <span>{{ $t('end.we_hope') }}</span>
            </div>
        </div>
    </div>
</template>

<script>

    import {restoreTimeMilis} from '../../store/config';
    import {mapActions} from "vuex";

    export default {
        name: "End",

        data() {
            return {
                timeout: null,
            };
        },

        created() {
            // Don't reset state after each reservation
            // this.reloadAllData();
            // this.restoreState();

            this.timeout = setTimeout(() => this.close(), restoreTimeMilis.restoreTimeEnd);
        },

        methods: {
            ...mapActions('dataLoader', ['reloadAllData']),// not good
            ...mapActions('queueItemTo', ['restoreState']), //good

            close() {
                clearTimeout(this.timeout);
                this.$root.$emit('Start');
            },
        }
    }
</script>

<style scoped>
    #bye {
        color: white;
        font-size: 2.5em;
        font-weight: bolder;
    }

    #hope {
        color: white;
        font-weight: bolder;
        font-size: x-large;
        margin-top: 20px;
    }

</style>
