
const state = {
    closedHoursReasons: []
};

const getters = {
    getClosedHoursReasons: state => state.closedHoursReasons,

    getClosedHoursReason: state => id => {
        return state.closedHoursReasons.find(closedHoursReasons => closedHoursReasons.id === id)
    },

};

const actions = {

    resetClosedHoursReasons({commit}) {
        commit('flushData');
    },

    setClosedHoursReasons({commit}, data){
        commit('setClosedHoursReasons',data)
    }
};

const mutations = {
    setClosedHoursReasons: (state, closedHoursReasons) => (state.closedHoursReasons = closedHoursReasons),

    flushData(state) {
        state.closedHoursReasons = [];
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
}
