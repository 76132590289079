import axios from 'axios';
import { fullURL, backend_urls } from '../config';
import moment from "moment";

const state = {
    didLoadedAssistantBalance: false,
    assistantBalance: []
};

const getters = {
    getAssistantBalance: state => state.assistantBalance,

    didLoadedAssistantBalance: state => state.didLoadedAssistantBalance,
};

const actions = {

    //FETCH ALL
    async fetchAssistantBalance({commit}, id) {
        commit('setDidLoadedAssistantBalance', false);
        await axios.post(fullURL.url + backend_urls.assistantBalance, {
            "studyFieldId": id,
            "startTime": moment().format("HH:mm").toString(),
            "wantedDay": new Date().toISOString().substr(0, 10),
        }).then((response) => {
            commit('setAssistantBalance', response.data.items);
            commit('setDidLoadedAssistantBalance', true);
        });
    },

    resetAssistantBalance({commit}) {
        commit('flushData');
    },
};

const mutations = {
    setAssistantBalance: (state, assistantBalance) => (state.assistantBalance = assistantBalance),

    setDidLoadedAssistantBalance: (state, value) => (state.didLoadedAssistantBalance = value),

    flushData(state) {
        state.assistantBalance = [];
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}
