
const state = {
    studyRequirement: [

    ]
};

const getters = {
    getStudyRequirement: state => state.studyRequirement,

    getSingleStudyRequirement: state => id => {
        return state.studyRequirement.find(studyField => studyField.id === id)
    },

    areStudyProblemsItemsFilled: state => {
        return state.studyRequirement.length !== 0;
    }
};

const actions = {

    resetStudyRequirement({commit}) {
        commit('flushData');
    },

    setStudyRequirement({commit}, data){
        commit('setStudyRequirement',data)
    }
};

const mutations = {
    setStudyRequirement: (state, studuRequirement) => (state.studyRequirement = studuRequirement),

    flushData(state) {
        state.studyRequirement = [];
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}
