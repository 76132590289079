<template>
    <div class="base_layer vld-parent">

        <Loading :active.sync="isLoadingData"
                 :is-full-page="false"
                 :height="125"
                 :width="125"
                 backgroundColor="#6D717A"
                 color="#7ABE12"
        ></Loading>

        <div class="row">
            <div id="row_1">
                <a href="#" @click.prevent="goBackReal"> <img id="back_button" src="../../assets/images/arrow.png"> </a>
            </div>
            <div id="row_2">
                <h1 id="top_row_header">{{ $t('study_requirement_recap.chosen_demands') }}</h1>
            </div>
            <div class="row_3">
                <digital-clock class="clock"></digital-clock>
            </div>
        </div>

        <div class="ps">
            <vue-scroll ref="vs" :ops="ops">
                <div>
                    <div class="in_middle">
                        <span v-if="$i18n.locale === 'en'" class="want_ticket_wide">{{returnStudyField.name_en}}</span>
                        <span v-else class="want_ticket_wide">{{cutText(returnStudyField.name)}}</span>
                    </div>
                </div>
            </vue-scroll>
        </div>

        <div class="row_bottom">
            <div class="generate_ticket_button">
                <button id="generate_ticket" @click="showLoader()">
                    {{ $t('study_requirement_recap.generate') }}
                </button>
            </div>
            <div class="generate_ticket_button_right">
                <go-cancel-arrow></go-cancel-arrow>
            </div>
        </div>

    </div>
</template>

<script>
    import DigitalClock from "@/components/elements/Clock.vue"
    import GoCancelArrow from "@/components/elements/GoCancelArrow";

    import moment from "moment";

    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

    import {mapGetters, mapActions} from "vuex";

    import {restoreTimeMilis} from '../../store/config';

    export default {
        name: "StudyRequirementRecapMeetings",
        components: {
            DigitalClock,
            GoCancelArrow,
            Loading,
        },

        data() {
            return {
                isLoadingData: false,
                didFinishLoadingData: false,

                ops: {
                    vuescroll: {},
                    scrollPanel: {},
                    rail: {},
                    bar: {
                        onlyShowBarOnScroll: false,
                    }
                },

                timeout: null,
            }
        },

        created() {
            this.timeout = setTimeout(() => this.$root.$emit('Start'), restoreTimeMilis.restoreTime);
        },

        destroyed() {
            clearTimeout(this.timeout);
        },

        computed: {
            returnStudyField() {
                return this.getSingleStudyField(this.getStudyFieldIdMeeting);
            },

            ...mapGetters('queueItemTo', ['getStudyFieldIdMeeting', 'getDidLoadedData', 'getIsDownloadingData']),
            ...mapGetters(['getSingleStudyField']),
        },

        methods: {
            goBackReal() {
                this.$root.$emit('toIdentity');
            },

            async send() {
                try {
                    this.setStartTime(moment().format("HH:mm").toString());
                    this.setEndTime(moment().add(this.returnStudyField.duration,"minutes").format("HH:mm").toString());

                    await this.sendQueueTo();

                    await this.getImage();

                    this.goToTicket()
                } catch (e) {
                    if (e === 409) {
                        this.isLoadingData = false;
                    }
                    this.$root.$emit('toError');
                }
            },
            ...mapActions('queueItemTo', ['sendQueueTo', 'setStartTime', 'setEndTime', 'getImage']),

            async showLoader() {
                this.isLoadingData = true;
                await this.send();
            },

            goToTicket() {
                this.isLoadingData = false;
                this.$root.$emit('toTicket');
            },

            cutText(text) {
                if (text.length > 85) {
                    return text.slice(0, 82) + '...';
                } else {
                    return text;
                }
            },
        },
    }
</script>

<style scoped>
    .base_layer {
        justify-content: space-between;
        background-color: #6D717A;
    }

    #top_row_header {
        padding-left: 15%;
        min-width: 55%;
        padding-right: 15%;
        font-size: xx-large;
        color: white;
        margin-top: 3%;
    }

    #row_1 {
        width: 20%;
        display: inline;
        padding: 0;
        margin: 0;
        float: left;
    }

    #row_2 {
        /*min-height: 300px;*/
        width: 58%;
        display: inline;
        padding: 0;
        margin: 0;
        float: left;
    }

    .row_3 {
        width: 20%;
        display: inline;
        padding: 0;
        margin: 0;
        float: left;
    }

    .in_middle {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .in_middle2 {
        margin-top: 25px;
        display: inline-flex;
    }

    .want_ticket_wide {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 950px;
        height: 80px;
        background-color: var(--base-color);
        border-radius: 40px;
        color: white;
        font-size: x-large;
        border: none;
        -webkit-box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
        -moz-box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
        box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
        font-weight: bolder;
        margin-bottom: 1rem;
    }

    .row {
        display: inline-block;
        width: 100%;
        max-height: 100px;
    }

    .ps {
        width: 100%;
        display: block;
    }

    .x_sign_rounded {
        background-image: url("../../assets/images/x_sign.png");
        background-size: 64px 64px;
        width: 64px;
        height: 64px;
        overflow: hidden;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);
        -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
        -moz-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
        border-width: 0;
        background-color: red;
        margin-left: 15px;
    }

    .plus_sign_rounded {
        background-image: url("../../assets/images/plus_sign.png");
        background-size: 64px 64px;
        width: 64px;
        height: 64px;
        overflow: hidden;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);
        -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
        -moz-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
        border-width: 0;
        background-color: var(--base-color);
    }

    .want_ticket_next {
        width: 260px;
        margin-left: 15px;
        color: white;
        font-size: x-large;
        font-weight: bolder;
    }

    #test {
        margin-top: 16px;
        position: absolute;
        display: inline-block;
    }

    #generate_ticket {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 330px;
        height: 100px;
        background: #7ABE12;
        border-radius: 40px;
        color: white;
        font-size: x-large;
        border: none;
        -webkit-box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
        -moz-box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
        box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
        font-weight: bolder;
        float: right;
        margin-right: 40px;
        margin-top: 30px;
    }

    #generate_ticket:active {
        background-color: white;
        color: black;
        transform: translateY(1px);
        filter: saturate(150%);
    }

    #generate_ticket:disabled {
        background-color: #a9a9ad;
        color: #727272;
    }

    .generate_ticket_button {
        float: left;
        position: absolute;
        left: 50%;
        bottom: 20%;
        transform: translateX(-50%);
    }

    .generate_ticket_button_right {
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 0.5em;
        display: flex;
        justify-content: center;
    }

    .row_bottom {
        width: 100%;
        height: 190px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-self: flex-end;
        padding: 0.5em;
        position: relative;
    }

    .add_next {
        width: 360px;
        margin-left: -340px;
    }

    #back_button {
        width: 35%;
        margin-left: -85px;
    }

    .clock {
        color: white !important;
    }
</style>
