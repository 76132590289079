<template>

  <div v-if="loading === true" class="base_layer notification">
    <Loading style="margin-top: 12rem"></Loading>
  </div>

  <div v-else class="base_layer wizard">

    <Header :text="$t('study_field.choose_studied_field')" @back="goBack()"/>

    <div class="cards-wrapper ps" ref="studyFieldScroll">
      <div class="card" v-for="field in filteredStudyFields" :key="field.id">
        <button class="want_ticket" v-on:click="clickManager(field.id)">
          {{ translateStudyType(field.studyType) + cutText(field.name) }}
        </button>
      </div>
    </div>

    <div class="footer">
      <go-cancel-arrow/>
    </div>
  </div>
</template>

<script>
import Header from "@/components/elements/Header";
import Loading from "@/components/elements/Loading";
import GoCancelArrow from "@/components/elements/GoCancelArrow";
import moment from "moment";

import {mapActions, mapGetters} from "vuex";

import {restoreTimeMilis} from '@/store/config';

export default {
  name: "StudyField",
  components: {
    GoCancelArrow,
    Loading,
    Header
  },

  data() {
    return {
      timeout: null,

      studyFields: null,
      studyFieldType: null,

      loading: false,
    }
  },

  created() {
    this.setStudyFieldTypeFromStorage();
    this.setCorrectLanguage();

    this.timeout = setTimeout(() => {
      this.$root.$emit('Start');
      this.restoreState();
    }, restoreTimeMilis.restoreTimeTicket);
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs.studyFieldScroll.scrollTo(0, this.getStudyFieldYAxis);
    })
  },

  destroyed() {
    clearTimeout(this.timeout);
  },

  computed: {
    ...mapGetters('scrollTo', ['getStudyFieldYAxis']),
    ...mapGetters(['getAssistantBalance', 'didLoadedAssistantBalance', 'getStudyCategory', 'getStudyFieldsByTargetLanguageSortedById', 'getStudyFieldType', 'getStudyRestrictions']),

    filteredStudyFields() {
      return this.studyFields.filter(f => f.type === this.getStudyFieldType);
    },
  },

  methods: {
    setStudyFieldTypeFromStorage() {
      this.studyFieldType = this.getStudyFieldType;
    },

    translateStudyType(status) {
      switch (status) {
        case 'Master':
          return this.$t('study_field.Master') + " - ";
        case 'Bachelor':
          return this.$t('study_field.Bachelor') + " - ";
        case 'Other':
          return "";
      }
    },

    setSFieldId(id) {
      this.setFieldId(id);
      this.$root.$emit('toStudyCategory');
    },

    async clickManager(id) {
      this.loading = true;
      this.setStudyFieldYAxis(this.$refs.studyFieldScroll.scrollTop)

      try {
        await this.fetchAssistantBalance(id);

        if (this.didLoadedAssistantBalance === true) {
          if (this.getAssistantBalance.length > 0) {
            this.loading = false;
            if (this.studyFieldType === "other_type") {
              this.setStudyFieldIdMeetings(id);
            } else {
              this.setSFieldId(id);
            }
          } else {
            this.loading = false;
            this.$root.$emit('toNoAssistant');
          }
        }
      } catch {
        this.$root.$emit('toError');
      }
    },

    setStudyFieldIdMeetings(id) {
      this.setStudyFieldIdMeeting(id);
      this.setFieldId(id);
      this.$root.$emit('toMeeting');
    },

    ...mapActions('queueItemTo', ['setFieldId', 'setStudyFieldIdMeeting', 'restoreState']),
    ...mapActions(['resetStudyFieldTypeStudy', 'fetchAssistantBalance']),
    ...mapActions('scrollTo', ['setStudyFieldYAxis']), //good

    setCorrectLanguage() {
      let items = this.getStudyFieldsByTargetLanguageSortedById(this.$i18n.locale);
      let retArray = [];

      for (let item of items) {
        let dataPrototype = {
          type: item.type,
          id: item.id,
          assistantsIds: this.findIfRestriction(item.assistantsIds),
          name: null,
          studyType: item.studyType,
        }

        if (this.$i18n.locale === 'en') {
          dataPrototype.name = item.name_en;
        } else {
          dataPrototype.name = item.name;
        }

        retArray.push(dataPrototype);
      }

      this.studyFields = retArray;
    },

    disabledIfNoOwnerOrChildren(field) {
      return this.disabledIfNoOwner(field) || this.disabledIfNoChildren()
    },

    disabledIfNoOwner(field) {
      return field.assistantsIds.length === 0;
    },

    disabledIfNoChildren() {
      return this.getStudyCategory.length === 0;

    },

    findIfRestriction(array) {
      let restrictionAssIds = this.cleanRestrictions();

      return array.filter((item) => !restrictionAssIds.includes(item));
    },

    cleanRestrictions() {
      let ret = this.getStudyRestrictions;
      let toReturn = [];

      if (ret.length !== 0) {
        for (let item of ret) {
          if (moment().isAfter(item.timeFrom) && moment().isBefore(item.timeTo) && item.closedHoursReason.type === 'person') {
            toReturn.push(item.assistantId);
          }
        }
      }

      return [...new Set(toReturn)];
    },

    goBack() {
      this.resetStudyFieldTypeStudy();
      this.$root.$emit('toIdentity');
    },

    cutText(text) {
      if (text.length > 40) {
        return text.slice(0, 38) + '...';
      } else {
        return text;
      }
    }
  }
}
</script>

<style scoped>

</style>
