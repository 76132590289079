<template>
  <div class="base_layer wizard">

    <Header :text="$t('study_requirement.select_concrete_demand')" @back="goBack()"/>


    <div class="cards-wrapper ps" ref="studyRequirementScroll">
      <div class="card" v-for="requirement in requirements" :key="requirement.id">
        <button class="want_ticket want_ticket_wide" v-on:click="clickManager(requirement.id)">
          {{ cutText($i18n.locale === 'en' ? requirement.name_en : requirement.name) }}
        </button>
      </div>
    </div>


    <div class="footer">
      <go-cancel-arrow/>
    </div>

  </div>
</template>

<script>
import Header from "@/components/elements/Header.vue"
import GoCancelArrow from "@/components/elements/GoCancelArrow";
import {mapActions, mapGetters} from "vuex";
import {restoreTimeMilis} from '@/store/config';

export default {
  name: "StudyRequirement",
  components: {
    Header,
    GoCancelArrow
  },

  data() {
    return {
      timeout: null,
      study_category_code: null,
      requirements: [],
    }
  },

  created() {
    this.setStudyCategoryCode();

    this.requirements = this.retStudyRequirementsTest();

    this.interval = setInterval(() => {
      this.requirements = this.retStudyRequirementsTest();
    }, 5000)

    if (this.requirements.length === 0) {
      this.$root.$emit('toStudyCategory');
    }

    this.timeout = setTimeout(() => this.$root.$emit('Start'), restoreTimeMilis.restoreTime);
  },


  destroyed() {
    clearTimeout(this.timeout);
    clearInterval(this.interval);
  },

  computed: {
    ...mapGetters(['getStudyRequirement', 'getSingleStudyField']),
    ...mapGetters('queueItemTo', ['getStudyCategoryId', 'getStudyRequirementIds', 'getSingleStudyRequirementId', 'getStudyRequirementLength']),
    ...mapGetters('scrollTo', ['getStudyRequirementYAxis'])

  },
  methods: {

    retStudyRequirementsTest() {
      let trueRequirements = [];
      let codeRequirements = [];

      const allRequirements = this.getStudyRequirement;
      const pickedRequirements = this.getStudyRequirementIds;
      const code = this.study_category_code;

      for (let p of allRequirements) {
        if (p.studyProblemsCategoryId === parseInt(code) && p.isShown === true) {
          codeRequirements.push(p);
        }
      }

      trueRequirements = codeRequirements.filter((item) => !pickedRequirements.includes(item.id));

      return trueRequirements;
    },

    setStudyCategoryCode() {
      this.study_category_code = this.getStudyCategoryId;
    },

    clickManager(requirementId) {
      this.setStudyRequirementYAxis(this.$refs.studyRequirementScroll.scrollTop)
      this.addRequirementId(requirementId);
      this.$root.$emit('toStudyRequirementRecap');
    },

    cutText(text) {
      if (text.length > 85) {
        return text.slice(0, 82) + '...';
      } else {
        return text;
      }
    },

    goBack() {
      this.setStudyRequirementYAxis(this.$refs.studyRequirementScroll.scrollTop)
      this.resetStudyCategoryId();
      this.$root.$emit('toStudyCategory');
    },

    ...mapActions('queueItemTo', ['addRequirementId', 'resetStudyCategoryId']),
    ...mapActions('scrollTo', ['setStudyRequirementYAxis'])
  },
  mounted() {
    this.requirements = this.retStudyRequirementsTest();
    this.$nextTick(() => {
      this.$refs.studyRequirementScroll.scrollTo(0, this.getStudyRequirementYAxis);
    })
  }
}
</script>

<style scoped>
.want_ticket_wide {
  min-width: 1000px;
}

</style>
