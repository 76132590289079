import {fullURL, backend_urls} from '../config';
import axios from 'axios';

const state = {
    isicCardNumber: "Empty",
    nameSurnameTitle: "Empty",
    xname: "Empty",
    studyFieldId: null,
    studyCategoryId: null,
    studyRequirementIds: [],
    personId: null,
    ticketNumber: null,
    queueItemUUID: null,

    startTime: null,
    endTime: null,

    isDowloadingData: false,
    DidLoadedData: false,

    studyFieldIdMeeting: null,

    imagesId: null,

    image: null,
};

const getters = {
    getStudyRequirementIds(state) {
        return state.studyRequirementIds;
    },

    getTicketNumber: state => state.ticketNumber,

    getQueueItemUUID: state => state.queueItemUUID,

    getSingleStudyRequirementId: state => id => {
        return state.studyRequirementIds.find(study => study == id)
    },

    getStudyRequirementLength(state) {
        return state.studyRequirementIds.length;
    },

    getStudyCategoryId() {
        return state.studyCategoryId;
    },

    getStudyFieldIdMeeting() {
        return state.studyFieldIdMeeting;
    },

    getPersonId(state) {
        return state.personId;
    },

    getDidLoadedData(state) {
        return state.DidLoadedData;
    },

    getIsDownloadingData(state) {
        return state.isDowloadingData;
    },

    getStartTime(state) {
        return state.startTime;
    },

    getEndTime(state) {
        return state.endTime;
    },

    getImagesId(state) {
        return state.imagesId;
    },

    getImage(state) {
        return state.image;
    },
    getXname(state) {
        return state.xname;
    },

};

const actions = {

    async sendQueueTo({commit}) {
        let wantedDay = new Date().toISOString().substr(0, 10);

        commit('startDownloadData');
        await axios.post(fullURL.url + backend_urls.queueItem, {
            "isicCardNumber": state.isicCardNumber,
            "nameSurnameTitle": state.nameSurnameTitle,
            "studyFieldId": state.studyFieldId,
            "studyProblemsId": state.studyCategoryId,
            "xname": state.xname,
            "studyProblemsItemsIds": state.studyRequirementIds,
            "wantedDay": wantedDay,
            "flag": "panel",
            "timeFrom": state.startTime,
            "timeTo": state.endTime,
            "bypass": false,

        }).then((response) => {

            const assistantId = response.data.content.assistantId;
            const ticketNumber = response.data.content.ticketNumber;
            const queueItemUUID = response.data.content.queueItemUUID;

            const startTime = response.data.content.startTime;
            const endTime = response.data.content.endTime;

            const imagesId = response.data.content.imagesId;
            const xname = response.data.content.xname

            commit('setPersonId', assistantId);
            commit('addTicketNumber', ticketNumber);
            commit('addQueueItemUUID', queueItemUUID);

            commit('setStartTime', startTime);
            commit('setEndTime', endTime);

            commit('setImagesId', imagesId);
            commit('setXname',xname);

            commit('stopDownloadData');

        }).catch(error => {
            commit('stopDownloadData');
            throw error.response.status;
        });
    },

    async getImage() {

        let config = {
            url: fullURL.url + backend_urls.images + state.imagesId,
            method: 'GET',
            responseType: 'arraybuffer'
        }

        await axios(config)
            .then((response) => {
                let bytes = new Uint8Array(response.data);
                let binary = bytes.reduce((data, b) => data += String.fromCharCode(b), '');
                state.image = "data:image/png;base64," + btoa(binary);
            });
    },

    setFieldId({commit}, id) {
        commit('setStudyFieldId', id);
    },

    setStudyFieldIdMeeting({commit}, id) {
        commit('setStudyFieldIdMeeting', id);
    },

    setCategoryId({commit}, id) {
        commit('setStudyCategoryId', id);
    },

    addRequirementId({commit}, id) {
        commit('addRequirementIds', id);
    },

    removeRequirementId({commit}, id) {
        commit('removeRequirementIds', id);
    },

    restoreState({commit}) {
        commit('resetAll');
    },

    resetStudyCategoryId({commit}) {
        commit('setStudyCategoryId', null);
    },

    setStartTime({commit}, time) {
        commit('setStartTime', time);
    },

    setEndTime({commit}, time) {
        commit('setEndTime', time);
    },

    setImagesId({commit}, imagesId) {
        commit('setImagesId', imagesId);
    },

    setImage({commit}, image) {
        commit('setImage', image);
    },
    setXname({commit},xname){
        commit('setXname',xname);
    }

};

const mutations = {
    setStudyFieldId(state, studyFieldId) {
        state.studyFieldId = studyFieldId;
    },

    startDownloadData(state) {
        state.DidLoadedData = false;
        state.isDowloadingData = true;

    },

    stopDownloadData(state) {
        state.DidLoadedData = true;
        state.isDowloadingData = false;
    },

    setStudyFieldIdMeeting: (state, id) => (state.studyFieldIdMeeting = id),

    setPersonId: (state, id) => (state.personId = id),

    setStudyCategoryId: (state, studyCategoryId) => (state.studyCategoryId = studyCategoryId),

    addRequirementIds: (state, studyRequirementIds) => (state.studyRequirementIds.push(studyRequirementIds)),

    addTicketNumber: (state, number) => (state.ticketNumber = number),

    addQueueItemUUID: (state, queueItemUUID) => (state.queueItemUUID = queueItemUUID),

    removeRequirementIds: (state, index) => (state.studyRequirementIds.splice(index, 1)),

    setStartTime: (state, startTime) => (state.startTime = startTime),

    setEndTime: (state, endTime) => (state.endTime = endTime),

    setImagesId: (state, imagesId) => (state.imagesId = imagesId),

    setImage: (state, image) => (state.image = image),

    setXname: (state, xname) => (state.xname = xname),

    resetAll(state) {
        state.queueItemUUID = null;
        state.ticketNumber = null;
        state.studyCategoryId = null;
        state.studyFieldId = null;
        state.studyRequirementIds = [];

        state.startTime = null;
        state.endTime = null;

        state.DidLoadedData = false;
        state.isDowloadingData = false;

        state.imagesId = null;
        state.image = null;
        state.xname = null;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
