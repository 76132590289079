<template>
  <div class="base_layer wizard">

    <Loading :active.sync="isLoadingData"
             :is-full-page="false"
             :height="125"
             :width="125"
             backgroundColor="#6D717A"
             color="#7ABE12"
    ></Loading>

    <Header :text="$t('study_requirement_recap.chosen_demands')" @back="goBackReal()"/>

    <div class="wrapper ps" ref="studyRecapScroll">
      <div v-for="(req, index) in retStudyRequirementsIds" :key="req.id">
        <div class="in_middle">
          <span v-if="$i18n.locale === 'en'" class="want_ticket_wide">{{ req.name_en }}</span>
          <span v-else class="want_ticket_wide">{{ cutText(req.name) }}</span>
          <button class="x_sign_rounded" v-on:click="removeReq(index)"></button>
        </div>
      </div>

      <div class="in_middle2">
        <div class="add_next pointer" @click="goBack" v-if="retStudyRequirementsIds.length < 5">
          <button class="plus_sign_rounded"></button>
          <span v-if="retStudyRequirementsIds.length === 0"
                class="want_ticket_next">{{ $t('study_requirement_recap.add_demand') }}</span>
          <span v-else class="want_ticket_next">{{ $t('study_requirement_recap.another_demand') }}</span>
        </div>
      </div>

    </div>

    <div class="row_bottom">
      <div class="generate_ticket_button">
        <button id="generate_ticket" :disabled="this.isGenerateTicketDisabled === 0" @click="goToIdentification()">
          {{ $t('study_requirement_recap.continue') }}
        </button>
      </div>
      <div class="generate_ticket_button_right">
        <go-cancel-arrow></go-cancel-arrow>
      </div>
    </div>

  </div>
</template>

<script>
import Header from "@/components/elements/Header.vue"
import GoCancelArrow from "@/components/elements/GoCancelArrow";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import {mapGetters, mapActions} from "vuex";

import {restoreTimeMilis} from '@/store/config';

export default {
  name: "StudyRequirementRecap",
  components: {
    Header,
    GoCancelArrow,
    Loading,
  },

  data() {
    return {
      isLoadingData: false,
      didFinishLoadingData: false,

      timeout: null,
    }
  },

  created() {
    this.timeout = setTimeout(() => this.$root.$emit('Start'), restoreTimeMilis.restoreTime);
  },

  destroyed() {
    clearTimeout(this.timeout);
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs.studyRecapScroll.scrollTo(0, this.getRecapYAxis);
    })
  },

  computed: {
    isGenerateTicketDisabled() {
      return this.getStudyRequirementLength;
    },

    retStudyRequirementsIds() {
      const truerequirements = [];
      const allRequirements = this.getStudyRequirement;
      const pickedRequirements = this.getStudyRequirementIds;

      for (let p of pickedRequirements) {
        for (let a of allRequirements) {
          if (p === a.id) {
            truerequirements.push(a);
          }
        }
      }

      return truerequirements;
    },
    ...mapGetters('queueItemTo', ['getStudyRequirementIds', 'getStudyRequirementLength', 'getDidLoadedData', 'getIsDownloadingData']),
    ...mapGetters(['getStudyRequirement']),
    ...mapGetters('scrollTo', ['getRecapYAxis'])

  },

  methods: {
    goBack() {
      this.setStudyRecapYAxis(this.$refs.studyRecapScroll.scrollTop)
      this.$root.$emit('toStudyCategory');
    },

    goBackReal() {
      this.setStudyRecapYAxis(this.$refs.studyRecapScroll.scrollTop)
      if (parseInt(this.getStudyRequirementLength) === 0) {
        this.$root.$emit('toStudyCategory');
      } else {
        this.$root.$emit('toStudyRequirement');
      }
    },

    removeReq(id) {
      this.removeRequirementId(id);
    },
    ...mapActions('queueItemTo', ['removeRequirementId', 'setStartTime', 'setEndTime']),
    ...mapActions('scrollTo', ['setStudyRecapYAxis']),

    goToIdentification() {
      this.isLoadingData = true;
      this.$root.$emit('toIdentification');
    },

    cutText(text) {
      if (text.length > 85) {
        return text.slice(0, 82) + '...';
      } else {
        return text;
      }
    },
  },
}
</script>

<style scoped>
.in_middle {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.in_middle2 {
  margin-top: 25px;
}

.want_ticket_wide {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 950px;
  height: 80px;
  background-color: white;
  border-radius: 40px;
  color: rgb(0, 0, 0);
  font-size: x-large;
  border: none;
  font-weight: bolder;
}

.ps {
  width: 100%;
  display: block;
}

.x_sign_rounded {
  background-image: url("../../assets/images/x_sign.png");
  background-size: 64px 64px;
  width: 64px;
  height: 64px;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-width: 0;
  background-color: red;
  margin-left: 15px;
}

.plus_sign_rounded {
  background-image: url("../../assets/images/plus_sign.png");
  background-size: 64px 64px;
  width: 64px;
  height: 64px;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-width: 0;
  background-color: var(--base-color);
}

.want_ticket_next {
  width: 260px;
  margin-left: 15px;
  color: white;
  font-size: x-large;
  font-weight: bolder;
}

#generate_ticket {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 330px;
  height: 100px;
  background: #7ABE12;
  border-radius: 40px;
  color: white;
  font-size: x-large;
  border: none;
  -webkit-box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
  -moz-box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
  box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
  font-weight: bolder;
  float: right;
  /*margin-right: 40px;*/
  /*margin-top: 30px;*/
}

#generate_ticket:active {
  background-color: white;
  color: black;
  transform: translateY(1px);
  filter: saturate(150%);
}

#generate_ticket:disabled {
  background-color: #a9a9ad;
  color: #727272;
}

.generate_ticket_button {
  float: left;
  position: absolute;
  left: 50%;
  bottom: 20%;
  transform: translateX(-50%);
}

.generate_ticket_button_right {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0.5em;
  display: flex;
  justify-content: center;
}

.row_bottom {
  width: 100%;
  height: 190px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: flex-end;
  padding: 0.5em;
  position: relative;
}

.add_next {
  margin: 0 auto;
  width: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
