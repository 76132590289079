const state = {};

const getters = {};

const actions = {

    reloadAllData: ({dispatch}) => {
        dispatch('resetAllLoadedData');
    },

    resetAllLoadedData: ({dispatch}) => {
        dispatch('resetAssistants', null, {root: true});
        dispatch('resetStudyFields', null, {root: true});
        dispatch('resetStudyCategory', null, {root: true});
        dispatch('resetStudyRequirement', null, {root: true});
        dispatch('resetStudyOpenHours', null, {root: true});
        dispatch('resetStudyRestrictions', null, {root: true});
        dispatch('resetClosedHoursReasons', null, {root: true});
        dispatch('resetAssistantBalance', null, {root: true});
    },
};

const mutations = {};

export default {
    namespaced: true,
    actions,
    state,
    getters,
    mutations
}
