
const state = {
    assistants: [],
};

const getters = {
    getAssistants: state => state.assistants,

    getAssistantId: (state) => (id) => {
        return state.assistants.find(assist => assist.id === id)
    },

    areAssistantsFilled: state => {
        return state.assistants.length !== 0;
    }
};

const actions = {

    resetAssistants({commit}) {
      commit('flushData');
    },

    setAssistants({commit}, data){
        commit('setAssistants',data)
    }
};

const mutations = {
    setAssistants: (state, assistants) => (state.assistants = assistants),

    flushData(state) {
        state.assistants = [];
    }

};

export default {
    state,
    getters,
    actions,
    mutations
}
