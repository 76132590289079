
const state = {
    studyFields: [

    ]
};

const getters = {
    getStudyFields: state => state.studyFields,
    getStudyFieldsSortedById: state => state.studyFields.slice().sort((a, b) => a.id - b.id),
    getStudyFieldsByTargetLanguageSortedById: (_, getters) => language => 
        getters.getStudyFieldsSortedById.filter(s => s.targetLanguages.includes(language)),
    getSingleStudyField: state => id => {
        return state.studyFields.find(studyField => studyField.id === id)
    },

    getStudyFieldsByType: state => type => {
        return state.studyFields.find(studyField => studyField.type === type)
    },
    getStudyFieldsByTypeAndTargetLanguage: state => (type, language) => 
        state.studyFields.filter(s => s.type === type && s.targetLanguages.includes(language)),

    areStudyFieldsFilled: state => {
        return state.studyFields.length !== 0;
    }
};

const actions = {

    resetStudyFields({commit}) {
        commit('flushData');
    },

    setStudyFields({commit}, data){
        commit('setStudyFields',data)
    }

};

const mutations = {
    setStudyFields: (state, studuFields) => (state.studyFields = studuFields),

    flushData(state) {
        state.studyFields = [];
    }

};

export default {
    state,
    getters,
    actions,
    mutations
}
