<template>
    <div class="base_layer notification">
        <div id="basic">
            <div id="bye">
                <span>{{ $t('closed_pause.unfortunately') }}</span>
            </div>
            <div id="hope">
                <span>{{ $t('closed_pause.description') }}</span>
                <div v-if="this.currentDayStudyHours.openTime !== null">
                    <span>{{ $t('closed_pause.pause_ends_at') }}</span>
                    <span>{{ this.currentDayStudyHours.pauseEnd }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import moment from "moment";

    export default {
        name: "ClosedPause",

        data() {
            return {
                langInterval: null,
                currentDayStudyHours: null,
            }
        },

        created() {
            this.currentDayStudyHours = this.getStudyOpenHour(moment().day().toString());
            this.langInterval = setInterval(() => {
                this.changeLocale()
            }, 15000)
        },

        destroyed() {
            clearInterval(this.langInterval);
        },

        computed: {
            ...mapGetters(['getStudyOpenHours', 'getStudyOpenHour'])
        },

        methods: {
            changeLocale() {
                if (this.$i18n.locale === 'en') {
                    this.$i18n.locale = 'cz';
                } else {
                    this.$i18n.locale = 'en';
                }
            },
        }
    }
</script>

<style scoped>
    .base_layer {
        background-color: var(--base-color);
        position: flex;
        justify-content: center;
        align-items: center;
    }

    #bye {
        color: white;
        font-size: 3em;
        font-weight: bolder;
    }

    #hope {
        color: white;
        font-weight: bolder;
        font-size: 2em;
        margin-top: 20px;
    }

</style>
