import axios from 'axios';
import {fullURL, backend_urls} from '../config';

const state = {
    studyRestrictions: []
};

const getters = {
    getStudyRestrictions: state => state.studyRestrictions,

    areStudyRestrictionsFilled: state => {
        return state.studyRestrictions.length !== 0;
    }
};

const actions = {
    //Fetch ALL
    async fetchStudyRestrictions({commit}) {
        const response = await axios.get(fullURL.url + backend_urls.studyRestrictions);
        commit('setStudyRestrictions', response.data.items)
    },

    resetStudyRestrictions({commit}) {
        commit('flushData');
    },

    setStudyRestrictions({commit}, data){
        commit('setStudyRestrictions',data)
    }
};

const mutations = {
    setStudyRestrictions: (state, studyRestrictions) => (state.studyRestrictions = studyRestrictions),

    flushData(state) {
        state.studyRestrictions = [];
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
}
