<template>
    <div class="base_layer wizard">

        <Header :text="$t('identity.choose_type_of_study')" @back="goBack()" />

        <div class="cards-wrapper">
            <div class="card">
                <button class="want_ticket" :disabled="setDisabledIfNoChildren('daily_type')" @click="setStudyFieldType('daily_type')">{{
                    $t('identity.daily') }}
                </button>
            </div>
            <div class="card">
                <button class="want_ticket" :disabled="setDisabledIfNoChildren('weekly_type')" @click="setStudyFieldType('weekly_type')">{{
                    $t('identity.weekly') }}
                </button>
            </div>

            <div class="card">
                <button class="want_ticket" :disabled="setDisabledIfNoChildren('other_type')" @click="setStudyFieldType('other_type')">{{
                    $t('identity.other') }}
                </button>
            </div>
        </div>

        <div class="footer">
            <go-cancel-arrow></go-cancel-arrow>
        </div>

    </div>
</template>

<script>
    import Header from "@/components/elements/Header";
    import GoCancelArrow from "@/components/elements/GoCancelArrow";
    import {mapActions, mapGetters} from "vuex";
    import {restoreTimeMilis} from '../../store/config';

    export default {
        name: "Identity",

        components: {
            Header,
            GoCancelArrow,
        },

        data() {
            return {
                timeout: null,
            }
        },

        created() {
            this.timeout = setTimeout(() => this.$root.$emit('Start'), restoreTimeMilis.restoreTime);
        },

        destroyed() {
            clearTimeout(this.timeout);
        },

        computed: {
            ...mapGetters(['getStudyFieldsByTypeAndTargetLanguage']),
        },

        methods: {
            goBack() {
                this.$root.$emit('Start');
                this.restoreState();
            },

            setStudyFieldType(type) {
                this.setStudyFieldTypeStudy(type);
                this.$root.$emit('toStudyField');

            },

            setDisabledIfNoChildren(type) {
                return this.getStudyFieldsByTypeAndTargetLanguage(type, this.$i18n.locale).length === 0;
            },

            ...mapActions(['setStudyFieldTypeStudy']),
            ...mapActions('queueItemTo', ['restoreState']),
        },
    }
</script>

<style scoped>

</style>
