const state = {
    studyFieldType: '',

    callInCurrentComponent: "Start",
};

const getters = {
    getStudyFieldType: (state) => state.studyFieldType,

    getCallInCurrentComponent: state => state.callInCurrentComponent,
};

const actions = {

    setStudyFieldTypeStudy({commit}, type) {
        commit('setStudyFieldType', type);
    },

    resetStudyFieldTypeStudy({commit}) {
        commit('resetStudyFieldType');
    },

    setCallInCurrentComponentStorage({commit}, value) {
        commit('setCallInCurrentComponent', value);
    },

};

const mutations = {
    setStudyFieldType: (state, type) => (state.studyFieldType = type),

    resetStudyFieldType: (state) => (state.studyFieldType = ''),

    setCallInCurrentComponent: (state, value) => (state.callInCurrentComponent = value),
};

export default {
    state,
    getters,
    actions,
    mutations
}
