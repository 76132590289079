<template>
    <v-overlay id="overlay" opacity="0.35" :absolute="true" z-index="51">

        <h2>{{ $t('overlay.help') }}</h2>

    </v-overlay>

</template>

<script>
    export default {
        name: "MissingDataOverlay"
    }
</script>

<style scoped>

    #overlay {
        -webkit-box-shadow: 8px 36px 49px -6px rgba(0,0,0,0.71);
        -moz-box-shadow: 8px 36px 49px -6px rgba(0,0,0,0.71);
        box-shadow: 8px 36px 49px -6px rgba(0,0,0,0.71);
    }

</style>
