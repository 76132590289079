const state = {
    studyFieldYAxis: 0,
    studyCategoryYAxis: 0,
    studyRequirementYAxis: 0,
    studyRecapYAxis: 0
};

const getters = {
    getStudyFieldYAxis(state) {
        return state.studyFieldYAxis
    },
    getStudyCategoryYAxis(state) {
        return state.studyCategoryYAxis
    },
    getStudyRequirementYAxis(state) {
        return state.studyRequirementYAxis
    },
    getRecapYAxis(state) {
        return state.studyRecapYAxis
    }
};

const actions = {
    setStudyFieldYAxis({commit}, yAxis) {
        commit('setStudyFieldYAxis', yAxis)
    },
    setStudyCategoryYAxis({commit}, yAxis) {
        commit('setStudyCategoryYAxis', yAxis)
    },
    setStudyRequirementYAxis({commit}, yAxis) {
        commit('setStudyRequirementYAxis', yAxis)
    },
    setStudyRecapYAxis({commit}, yAxis) {
        commit('setStudyRecapYAxis', yAxis)
    }
};

const mutations = {
    setStudyFieldYAxis: (state, yAxis) => (state.studyFieldYAxis = yAxis),
    setStudyCategoryYAxis: (state, yAxis) => (state.studyCategoryYAxis = yAxis),
    setStudyRequirementYAxis: (state, yAxis) => (state.studyRequirementYAxis = yAxis),
    setStudyRecapYAxis: (state, yAxis) => (state.studyRecapYAxis = yAxis),
};

export default {
    namespaced: true,
    actions,
    state,
    getters,
    mutations
}