
const state = {
    studyOpenHours: []
};

const getters = {
    getStudyOpenHours: state => state.studyOpenHours,

    getStudyOpenHour: state => dayNumber => {
        return state.studyOpenHours.find(studyOpenHour => studyOpenHour.dayNumber === dayNumber);
    },

    areStudyOpenHoursFilled: state => {
        return state.studyOpenHours.length !== 0;
    }
};

const actions = {

    resetStudyOpenHours({commit}) {
        commit('flushData');
    },

    setStudyOpenHours({commit}, data){
        commit('setStudyOpenHours',data)
    }
};

const mutations = {
    setStudyOpenHours: (state, studyOpenHours) => (state.studyOpenHours = studyOpenHours),

    flushData(state) {
        state.studyOpenHours = [];
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
}
