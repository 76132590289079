import Vue from 'vue'
import App from './App.vue'

// import vuescroll from 'vuescroll';
// import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import VueQrcode from '@chenfengyuan/vue-qrcode';
import VueRouter from 'vue-router'


import store from './store/Store.js'
import routes from "@/router/router";
import i18n from "@/i18n/i18n";
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import vuetify from './vuetify/vuetify';

Vue.config.productionTip = false;

localStorage.debug = "axios";

Vue.component(VueQrcode.name, VueQrcode);
Vue.use(VueRouter);
Vue.use(Vuetify);
// Vue.use(vuescroll);

const router = new VueRouter({routes});

new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app');
