<template>
    <div class="header">
        <div class="back-arrow">
            <a v-if="$listeners.back" href="#" @click.prevent="goBack"> <img id="back_button" src="../../assets/images/arrow.png" alt="arrow"> </a>
        </div>
        <div class="heading">
            <h1 id="top_row_header">{{ text }}</h1>
        </div>
        <digital-clock></digital-clock>
    </div>
</template>

<script>
    import DigitalClock from "@/components/elements/Clock.vue"

    export default {
        name: "Header",

        components: {
            DigitalClock,
        },

        props: {
            text: String
        },

        methods: {
            goBack() {
                this.$emit('back');
            },
        },
    }
</script>

<style scoped>
    .header {
        width: 100%;
        height: 8vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .header > div {
        padding: 0 3vw;
    }

    .back-arrow {
        width: 14vw;
    }

    #back_button {
        width: 100%;
    }

    .heading {
        color: white;
    }

    .clock {
        width: 14vw;
        color: white;
    }

</style>
