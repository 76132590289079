
const state = {
    studyCategory: [

    ]
};

const getters = {
    getStudyCategory: state => state.studyCategory,

    areStudyProblemsCategoryFilled: state => {
        return state.studyCategory.length !== 0;
    }
};

const actions = {

    resetStudyCategory({commit}) {
        commit('flushData');
    },

    setStudyCategory({commit}, data){
        commit('setStudyCategory',data)
    }

};

const mutations = {
    setStudyCategory: (state, studuCategory) => (state.studyCategory = studuCategory),

    flushData(state) {
        state.studyCategory = [];
    }

};

export default {
    state,
    getters,
    actions,
    mutations
}
