<template>
    <div class="base_layer notification">
        <div id="basic">
            <div id="bye">
                <span>{{ $t('closed.unfortunately') }}</span>
            </div>
            <div class="hope">
                <span>{{ $t('closed.description') }}</span>
            </div>
            <div class="hope">
                <span v-if="this.$i18n.locale === 'en'">Reason: {{model.reasonEng}}</span>
                <span v-else>Důvod: {{model.reasonCz}}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import moment from 'moment';

    export default {
        name: "ClosedDueTo",

        data() {
            return {
                langInterval: null,
                routeInterval: null,

                model: {
                    timeFrom: null,
                    timeTo: null,
                    reasonEng: null,
                    reasonCz: null,
                    wantedDay: null,
                    type: null,
                }
            }
        },

        async created() {
            await this.fetchStudyRestrictions();
            this.loadModel();
        },

        mounted() {
            this.langInterval = setInterval(() => {
                this.changeLocale();

            }, 15000)

            this.routeInterval = setInterval(async () => {
                await this.fetchStudyRestrictions();
                this.loadModel();
            }, 30000)
        },

        destroyed() {
            clearInterval(this.langInterval);
            clearInterval(this.routeInterval)
        },

        computed: {
            ...mapGetters(['getStudyRestrictions', 'getStudyOpenHour'])
        },

        methods: {
            ...mapActions(['fetchStudyRestrictions']),

            changeLocale() {
                if (this.$i18n.locale === 'en') {
                    this.$i18n.locale = 'cz';
                } else {
                    this.$i18n.locale = 'en';
                }
            },

            loadModel() {
                let preModel = this.getStudyRestrictions.find(function (model) {
                    return moment().isAfter(moment(model.timeFrom)) && moment().isBefore(moment(model.timeTo)) && model.closedHoursReason.type === 'department';
                });

                if (typeof preModel === "undefined" || preModel === null) {
                    this.$root.$emit('Start');
                } else {
                    if (moment().isAfter(moment(preModel.timeFrom)) || moment().isBefore(moment(preModel.timeTo))) {
                        this.model.reasonCz = preModel.closedHoursReason.reason;
                        this.model.reasonEng = preModel.closedHoursReason.reason_en;
                    } else {
                        this.$root.$emit('Start');
                    }
                }
            }
        }
    }
</script>

<style scoped>

    #bye {
        color: white;
        font-size: 3em;
        font-weight: bolder;
    }

    .hope {
        color: white;
        font-weight: bolder;
        font-size: 2em;
        margin-top: 20px;
    }
</style>
