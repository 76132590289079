<template>
  <div class="base_layer wizard">

    <Header :text="$t('study_category.select_category_demands')" @back="goBack()"/>


    <div class="cards-wrapper ps" ref="studyCategoryScroll">
      <div class="card" v-for="category in studyCategory" :key="category.id">
        <button :disabled="retStudyRequirementsTest(category.id)"
                class="want_ticket"
                v-on:click="clickManager(category.id)">{{ cutText(category.name) }}
        </button>
      </div>
    </div>

    <div class="footer">
      <go-cancel-arrow/>
    </div>

  </div>
</template>

<script>
import Header from "@/components/elements/Header";
import GoCancelArrow from "@/components/elements/GoCancelArrow";
import {mapActions, mapGetters} from "vuex";
import {restoreTimeMilis} from "@/store/config";

export default {
  name: "StudyCategory",

  components: {
    Header,
    GoCancelArrow
  },

  data() {
    return {
      studyCategory: null,

      timeout: null,
    }
  },

  created() {
    this.setCorrectLaguage();
    this.timeout = setTimeout(() => this.$root.$emit('Start'), restoreTimeMilis.restoreTime);
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs.studyCategoryScroll.scrollTo(0, this.getStudyCategoryYAxis);
    })
  },

  destroyed() {
    clearTimeout(this.timeout);
  },

  methods: {
    goBack() {
      if (parseInt(this.getStudyRequirementLength) === 0) {
        this.$root.$emit('toStudyField');
      } else {
        this.$root.$emit('toStudyRequirementRecap');
      }
    },

    setCorrectLaguage() {
      let items = this.getStudyCategory;
      let retArray = [];

      for (let item of items) {
        let dataPrototype = {
          type: item.type,
          id: item.id,
          code: item.code,
          name: null,
        }

        if (this.$i18n.locale === 'en') {
          dataPrototype.name = item.name_en;
        } else {
          dataPrototype.name = item.name;
        }

        retArray.push(dataPrototype);
      }

      this.studyCategory = retArray;
    },

    retStudyRequirementsTest(code) {
      let trueRequirements = [];
      let codeRequirements = [];

      const allRequirements = this.getStudyRequirement;
      const pickedRequirements = this.getStudyRequirementIds;

      for (let p of allRequirements) {
        if (p.studyProblemsCategoryId === code && p.isShown === true) {
          codeRequirements.push(p);
        }
      }

      trueRequirements = codeRequirements.filter((item) => !pickedRequirements.includes(item.id));

      return trueRequirements.length === 0;
    },

    cutText(text) {
      if (text.length > 40) {
        return text.slice(0, 38) + '...';
      } else {
        return text;
      }
    },

    clickManager(categoryId) {
      this.setStudyCategoryYAxis(this.$refs.studyCategoryScroll.scrollTop)
      this.setCategoryId(categoryId);
      this.$root.$emit('toStudyRequirement');
    },
    ...mapActions('queueItemTo', ['setCategoryId']),
    ...mapActions('scrollTo', ['setStudyCategoryYAxis'])
  },

  computed: {
    ...mapGetters('queueItemTo', ['getStudyRequirementIds', 'getStudyRequirementLength']),
    ...mapGetters(['getStudyCategory']),
    ...mapGetters(['getStudyRequirement']),
    ...mapGetters('scrollTo', ['getStudyCategoryYAxis'])
  },

}
</script>

<style scoped>

</style>
